import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import NextButton from 'components/Common/NextButton';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import { findAccount } from 'utils/api';
import config from 'configuration';
import AuthWrapper from './AuthWrapper';
import useTranslation from '../../hooks/useTranslation';

export default function GetStarted() {
  const { tGetStarted, tError, tCommon } = useTranslation();

  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const { toastError } = useToastContext();

  const { loginWithGoogle, request } = useAppContext();

  const handleGoogleLoginSuccess = async (token) => {
    setSubmitting(true);

    const { error } = await loginWithGoogle(token);

    if (error) {
      console.error(error);
      toastError(error);
    }

    setSubmitting(false);
  };

  const handleFindAccount = async () => {
    setSubmitting(true);

    const { error, result } = await request(findAccount(email.trim()));

    if (error) {
      history.push(`/signup?email=${encodeURIComponent(email.trim())}`);
    } else {
      let path = `/login?email=${encodeURIComponent(email.trim())}`;
      if (result.avatar) {
        path += `&avatar=${encodeURIComponent(result.avatar)}`;
      }
      history.push(path);
    }
  };

  return (
    <AuthWrapper>
      <div className="header">
        <div className="title">{tGetStarted('enterEmailToGetStarted')}</div>
        <div className="sub-title">{tGetStarted('logIntoAccount')}</div>
      </div>

      <div className="body">
        <GoogleOAuthProvider clientId={config.googleClientId}>
          <GoogleLogin
            size="large"
            onSuccess={async ({ credential }) => {
              if (credential) {
                handleGoogleLoginSuccess(credential);
              } else {
                toastError(tError('logInWithGoogle'));
              }
            }}
            onError={() => {
              toastError(tError('logInWithGoogle'));
            }}
            // TODO: set locale to support i18n
            // locale={i18n.language}
          />
        </GoogleOAuthProvider>
        <div className="separator">
          <span className="line left" />
          <span className="text">{tCommon('or')}</span>
          <span className="line right" />
        </div>

        <Form.Group className="form-group">
          <Form.Label>{tCommon('email')}</Form.Label>
          <Form.Control
            placeholder={tGetStarted('enterYourEmail')}
            disabled={submitting}
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleFindAccount();
              }
            }}
          />
        </Form.Group>

        <NextButton
          text={tCommon('continue')}
          className="submit-button"
          disabled={submitting || !isEmail(email.trim())}
          onClick={handleFindAccount}
        />
      </div>
    </AuthWrapper>
  );
}
