import React from 'react';
import { Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useModalContext } from 'components/ModalContext';
import { CheckBox } from 'auto-design-common';
import { ModalKey } from 'constants/modal';
import useTranslation from '../../hooks/useTranslation';

export default function WatermarkConfig({
  data,
  onChange,
  loading,
}) {
  const { tCommon } = useTranslation();
  const { showModal } = useModalContext();

  return (
    <div
      className="watermark-config"
    >
      <CheckBox
        title="Add custom watermark"
        checked={data.use}
        onChange={() => {
          onChange({
            ...data,
            use: !data.use,
          });
        }}
      />
      {data.use && (
      <>
        {data.images.map(image => (
          <div className="watermark-item" key={image.id}>
            <div
              onClick={() => {
                onChange({
                  ...data,
                  images: data.images.filter(i => i.id !== image.id),
                });
              }}
              className="delete-watermark-button"
            >
              {tCommon('delete')}
            </div>
            <img
              key={image.id}
              src={image.url}
              alt="watermark"
            />
          </div>
        ))}
        <Button
          disabled={loading}
          variant="link"
          className="add-watermark"
          onClick={() => {
            showModal(ModalKey.ADD_WATERMARK, {
              onSelect: url => {
                onChange({
                  ...data,
                  images: [
                    ...data.images,
                    {
                      id: uuidv4(),
                      x: 20,
                      y: 20,
                      scale: 1,
                      rotation: 0,
                      url,
                      initialized: false,
                    },
                  ],
                });
              },
            });
          }}
        >
          +
          {' '}
          {tCommon('add')}
        </Button>
      </>
      )}
    </div>
  );
}
