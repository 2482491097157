import React, { useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Editor } from 'auto-design-editor';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import darkLogo from 'assets/generated/images/logo-dark.png';
import { getFileUrl } from 'utils/file';

export default function EditImage() {
  const { imageId } = useParams();
  const { logout } = useAppContext();
  const history = useHistory();

  const onError = useCallback((error) => {
    console.error(error);
    if (error.code === 401) {
      logout();
    }
  }, [logout]);

  const onClickLogo = useCallback(() => {
    history.push('/');
  }, [history]);

  const editorProps = useMemo(() => ({
    onRender: (data) => api.render(imageId, data),
    onGetImage: () => api.getImage(imageId),
    onUpdateImage: (data) => api.updateImage(imageId, data),
    onGetCategories: () => api.getCategories(),
    onGetItems: ({ after, limit, roomId, categoryId }) => api.getItems({
      after,
      limit,
      roomId,
      categoryId,
    }),
    onGetPersonalCollectionItems: (collectionId) => api.getPersonalCollectionItems(collectionId),
    onGetPersonalCollections: () => api.getPersonalCollections(),
    onGetRooms: () => api.getRooms(),
    onGetRoom: (roomCategoryId) => api.getRoom(roomCategoryId),
    onGetLocation: (locationId) => api.getLocation(locationId),
    onGetItemCategories: (itemId) => api.getItemCategories(itemId),
    onAddItemToPersonalCollections: (itemId, collectionIds) => api.addItemToPersonalCollections(itemId, collectionIds),
    onGetPersonalCollectionsOfAnItem: (itemId) => api.getPersonalCollectionsOfAnItem(itemId),
    onCreatePersonalCollection: (data) => api.createPersonalCollection(data),
    onEditPersonalCollection: (collectionId, data) => api.editPersonalCollection(collectionId, data),
    onDeletePersonalCollection: (collectionId) => api.deletePersonalCollection(collectionId),
    enablePersonalCollections: true,
    enableSameLocation: true,
    onError,
    getFileUrl,
    renderLogo: (className) => (
      <img
        alt="Logo"
        src={darkLogo}
        className={className}
        onClick={() => {
          onClickLogo();
        }}
        height={56}
      />
    ),
    enableRender: true,
  }), [imageId, onError, onClickLogo]);

  return (
    <Editor
      {...editorProps}
    />
  );
}
