import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import c from 'configuration';
import translationEn from './en/translation.json';

const configs = c as any;

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: configs.language,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      translation: translationEn,
    },
  },
});
