import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Table } from 'react-bootstrap';
import * as api from 'utils/api';
import useFetch from 'hooks/useFetch';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import pluralize from 'pluralize';
import styles from './AutoStaging.module.scss';
import useTranslation from '../../hooks/useTranslation';

const animatedComponents = makeAnimated();

export default function AutoStaging({
  autoStaging,
  setAutoStaging,
}) {
  const { tAddDesign } = useTranslation();
  const { showModal } = useModalContext();
  const { result: rooms } = useFetch(api.getRooms, []);

  const numBundles = autoStaging.rooms.reduce((acc, room) => {
    if (room.bundle) {
      return acc + 1;
    }
    return acc;
  }, 0);

  console.log({
    autoStaging,
  });

  return (
    <div className={styles.autoStaging}>
      <div className={styles.selectRoom}>
        <div className={styles.selectRoomTitle}>
          {tAddDesign('rooms')}
        </div>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={autoStaging.rooms.map(r => ({
            value: r.id,
            label: r.name,
          }))}
          isMulti
          options={rooms.map(room => ({
            value: room.id,
            label: room.name,
          }))}
          classNames={{
            container: () => 'reactSelect',
          }}
          onChange={selectedRooms => {
            setAutoStaging({
              ...autoStaging,
              rooms: selectedRooms.map(option => {
                const previouselySelectedValue = autoStaging.rooms.find(r => r.id === option.value);

                if (previouselySelectedValue) {
                  return previouselySelectedValue;
                }

                return {
                  id: option.value,
                  name: option.label,
                  bundle: null,
                };
              }),
            });
          }}
        />
      </div>
      {autoStaging.rooms.length > 0 && (
        <>
          <div className={styles.selectBundle}>
            <div className={styles.selectBundleTitle}>
              {tAddDesign('templates')}
            </div>
            <div
              className={styles.bundles}
              onClick={() => {
                showModal(ModalKey.AUTO_STAGING_SELECT_BUNDLES, {
                  autoStaging,
                  onDone: value => {
                    setAutoStaging(value);
                  },
                });
              }}
            >
              {numBundles === 0 ? (
                <div className={styles.noBundle}>Select...</div>)
                : (
                  <div className={styles.noBundle}>
                    {numBundles}
                    {' '}
                    {pluralize('bundle', numBundles)}
                  </div>
                )}
            </div>
          </div>
          <Table className={styles.bundleTable}>
            <thead>
              <tr>
                <th>
                  {tAddDesign('room')}
                </th>
                <th>
                  {tAddDesign('template')}
                </th>
              </tr>
            </thead>
            <tbody>
              {autoStaging.rooms.map(room => (
                <tr>
                  <td>
                    {room.name}
                  </td>
                  <td>
                    {room.bundle?.name || 'None'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}
