import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { Button, Form, Modal } from 'react-bootstrap';
import ReactLoading from 'components/Common/ReactLoading';
import classNames from 'classnames';
import { useToastContext, AUTO_STAGING_PREFERENCES_SPECS } from 'auto-design-common';
import useFetch from 'hooks/useFetch';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import styles from './AutoStagingSelectBundles.module.scss';
import useTranslation from '../../hooks/useTranslation';

export default function AutoStagingSelectBundles({
  autoStaging,
  onDone,
  onClose,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tAutoStagingSelectBundles } = useTranslationOrigin('translation', { keyPrefix: 'modal.autoStagingSelectBundles' });

  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { result: rooms } = useFetch(api.getRooms, []);
  const [localAutoStaging, setLocalAutoStaging] = useState(autoStaging);
  const [currentRoomId, setCurrentRoomId] = useState(autoStaging.rooms[0].id);
  const [bundles, setBundles] = useState([]);
  const [loading, setLoading] = useState(false);
  const bundlesMap = useRef({});
  const currentRoom = useMemo(() => localAutoStaging.rooms.find(r => r.id === currentRoomId), [localAutoStaging, currentRoomId]);

  const fetchBundles = useCallback(async () => {
    if (bundlesMap.current[currentRoomId]) {
      setBundles(bundlesMap.current[currentRoomId]);
      return;
    }

    setLoading(true);
    setBundles([]);
    const { result, success, error } = await request(api.getRoom(currentRoomId));

    if (success) {
      setBundles(result.children);
      bundlesMap.current[currentRoomId] = result.children;
    } else {
      toastError(error);
    }

    setLoading(false);
  }, [currentRoomId, toastError, request]);

  useEffect(() => {
    fetchBundles();
  }, [fetchBundles]);

  return (
    <Modal
      show
      backdrop="static"
      id="modal-auto-staging"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          {tAutoStagingSelectBundles('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.rooms}>
          {localAutoStaging.rooms.map(room => {
            const roomDetails = rooms.find(r => r.id === room.id);

            return (
              <div
                className={classNames(styles.room, room.id === currentRoomId && styles.selectedRoom)}
                key={room.id}
                onClick={() => {
                  setCurrentRoomId(room.id);
                }}
              >
                {roomDetails?.name}
              </div>
            );
          })}
        </div>

        <div className={styles.right}>
          <div className={styles.bundles}>
            {loading && (
            <div className={styles.loading}>
              <ReactLoading />
            </div>
            )}
            {bundles.map(bundle => (
              <div
                key={bundle.id}
                className={styles.bundle}
                onClick={() => {
                  setLocalAutoStaging({
                    ...localAutoStaging,
                    rooms: localAutoStaging.rooms.map(room => {
                      if (room.id === currentRoomId) {
                        return {
                          ...room,
                          bundle: {
                            id: bundle.id,
                            name: bundle.name,
                          },
                        };
                      }

                      return room;
                    }),
                  });
                }}
              >
                <img
                  className={classNames(styles.bundleThumbnail, currentRoom.bundle?.id === bundle.id && styles.selectedBundleThumbnail)}
                  src={bundle.thumbnail}
                  alt={bundle.name}
                />
                <div className={styles.bundleName}>
                  {bundle.name}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.preferences}>
            <div className={styles.preferencesTitle}>
              {tAutoStagingSelectBundles('preferences')}
            </div>
            <div>
              {AUTO_STAGING_PREFERENCES_SPECS.map(spec => {
                const value = localAutoStaging.preferences[spec.key];
                const specValue = spec.options.find(o => o.value === value);

                return (
                  <Form.Group className={styles.formGroup} key={spec.key}>
                    <Form.Label>
                      {spec.name}
                    </Form.Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={specValue}
                      name={spec.name}
                      options={spec.options}
                      onChange={({ value }) => {
                        setLocalAutoStaging({
                          ...localAutoStaging,
                          preferences: {
                            ...localAutoStaging.preferences,
                            [spec.key]: value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                );
              })}

            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          onDone(localAutoStaging);
          onClose();
        }}
        >
          {tCommon('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
