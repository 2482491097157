// Settings configured here will be merged into the final config object.
export default {
  apiUrl: 'http://localhost:3001/api',
  paypalClientId: 'Ad5hzPlW8VG9SW166gMUH6vyzRp39cDytIidPNOztig3ghkvdw-vgz1k92wjxOWKPD0jzq_4Ed_nKxrR',
  googleClientId: '81150743761-ve6jrr5292ne8m4q07u972iqvasc8ivi.apps.googleusercontent.com',
  pusherKey: '18ae0063051bb0482307',
  pusherCluster: 'ap1',
  tenantId: process.env.REACT_APP_TENANT_ID,
  language: 'en',
  privacyPolicyUrl: 'https://virtualstagingunlimited.com/pages/privacy-policy',
  termsOfServicesUrl: 'https://virtualstagingunlimited.com/pages/terms-of-service',
  disablePanoImage: false,
};
